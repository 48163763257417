const defaultCenter = [60.192059, 24.945831]; //Somewhere in Espoo i guess
const defaultTextOptions = {
  h1: "unset",
  h2: "unset",
  h3: `${defaultCenter[0]}° N / ${defaultCenter[1]}° E`,
  h1Visible: true,
  h2Visible: true,
  h3Visible: true,
  font: "Verdana",
  color: "#FFFFFF",
};
const defaultCanvasOption = {
  type: "Paper print",
  orientation: "Landscape",
  size: "A4",
};
const defaultLineOptions = {
  color: "#0CB1E8",
  weight: 1,
  opacity: 0.5,
  smoothFactor: 1,
};
const defaultFilter = {
  minDate: null,
  maxDate: null,
  activityTypes: [],
};
const defaultTheme = [
  "Dark map",
  "https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png",
];

const allCanvasOptions = {
  type: ["Paper print", "Canvas"],
  orientation: ["Landscape", "Portrait"],
  size: ["A4", "A3", "30 x 30 cm", "50 x 50 cm", "70 x 50 cm"],
};

const allThemeOptions = [
  ["Basic Map", "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"],
  [
    "Dark Map",
    "https://{s}.basemaps.cartocdn.com/rastertiles/dark_all/{z}/{x}/{y}.png",
  ],
  [
    "Dark Map No Labels",
    "https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png",
  ],
  [
    "Light Map",
    "https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png",
  ],
  [
    "Light Map No Labels",
    "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png",
  ],
  [
    "Monochrome Map",
    "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png",
  ],
  ["Colorful Map", "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"],
  ["Topological Map", "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"],
  [
    "Midnight Map",
    "https://cartocdn_{s}.global.ssl.fastly.net/base-midnight/{z}/{x}/{y}.png",
  ],
  ["Black", "black"],
  ["White", "white"],
];

const allFontOptions = [
  "Verdana",
  "Arial",
  "Times New Roman",
  "Garamond",
  "Freestyle Script",
];

const allPrices = {
  "Paper print": {
    A4: 29.0,
    A3: 39.0,
    "30 x 30 cm": 35.0,
    "50 x 50 cm": 49.0,
    "70 x 50 cm": 59.0,
  },
  Canvas: {
    A4: 79.0,
    A3: 99.0,
    "30 x 30 cm": 89.0,
    "50 x 50 cm": 119.0,
    "70 x 50 cm": 129.0,
  },
};
export const popularOptions = [
  {
    name: "dark",
    text: {
      font: "Garamond",
      color: "#FFFFFF",
    },
    line: {
      color: "#0CB1E8",
      weight: 1,
      opacity: 0.7,
      smoothFactor: 1,
    },
    theme: [
      "Dark Map No Labels",
      "https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png",
    ],
  },
  {
    name: "colorful",
    text: {
      font: "Freestyle Script",
      color: "#000000",
    },
    line: {
      color: "#ef2626",
      weight: 3,
      opacity: 1,
      smoothFactor: 1,
    },
    theme: [
      "Colorful Map",
      "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
    ],
  },
  {
    name: "light",
    text: {
      font: "Arial",
      color: "#000000",
    },
    line: {
      color: "#000000",
      weight: 2,
      opacity: 0.4,
      smoothFactor: 1,
    },
    theme: [
      "Light Map No Labels",
      "https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png",
    ],
  },
  {
    name: "midnight",
    text: {
      font: "Verdana",
      color: "#FFFFFF",
    },
    line: {
      color: "#FFFFFF",
      weight: 1,
      opacity: 1,
      smoothFactor: 1,
    },
    theme: [
      "Midnight Map",
      "https://cartocdn_{s}.global.ssl.fastly.net/base-midnight/{z}/{x}/{y}.png",
    ],
  },
];

export const productVariants = {
  "Paper print": {
    A4: "34509852016684",
    A3: "34509852082220",
    "30 x 30 cm": "34509852147756",
    "50 x 50 cm": "34509852213292",
    "70 x 50 cm": "34509852278828",
  },
  Canvas: {
    A4: "34509852049452",
    A3: "34509852114988",
    "30 x 30 cm": "34509852180524",
    "50 x 50 cm": "34509852246060",
    "70 x 50 cm": "34509852311596",
  },
};

export const defaultOptions = {
  defaultCenter,
  defaultTextOptions,
  defaultCanvasOption,
  defaultLineOptions,
  defaultFilter,
  defaultTheme,
};

export const allOptions = {
  allCanvasOptions,
  allThemeOptions,
  allFontOptions,
  allPrices,
};
