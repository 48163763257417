import React from "react";
import { Map, TileLayer, Polyline } from "react-leaflet";
import LocationText from "../location-text";
import "./mapContainer.css";
import { defaultOptions } from "../../utils/customizationOptions.js";
import { getAddress } from "../../utils/reverseGeocode.js";

const MapContainer = ({
  canvasOptions,
  data,
  lineOptions,
  textOptions,
  setTextOptions,
  theme,
  mapRef,
  center,
  setCenter,
  zoom,
  setZoom,
}) => {
  const [width, setWidth] = React.useState(0);
  const [mapKey, setMapKey] = React.useState(0);
  const [scaledHeight, setScaledHeight] = React.useState("100%");
  const [scale, setScale] = React.useState(1);
  const [styles, setStyles] = React.useState({});

  const parentEl = React.useRef(null);

  React.useLayoutEffect(() => {
    parentEl.current && setWidth(parentEl.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    const { orientation, size, type } = canvasOptions;
    const toPixel = (cm) => cm * 59.05511811; // 150dpi
    const generateSizes = (sizeOption) => {
      if (sizeOption === "A4") return [toPixel(29.7), toPixel(21)];
      if (sizeOption === "A3") return [toPixel(42), toPixel(29.7)];
      return [toPixel(sizeOption.slice(0, 2)), toPixel(sizeOption.slice(5, 7))];
    };
    const sizes = generateSizes(size);
    if (type === "Canvas") {
      sizes[0] += toPixel(2.5);
      sizes[1] += toPixel(2.5);
    }
    const canvasWidth = orientation === "Portrait" ? sizes[1] : sizes[0];
    const canvasHeight = orientation === "Portrait" ? sizes[0] : sizes[1];
    const scaler = width / canvasWidth;
    setStyles({
      width: `${canvasWidth}px`,
      height: `${canvasHeight}px`,
      transform: `scale(${scaler})`,
      transformOrigin: "top left",
      backgroundColor: `${theme[1]}`,
    });
    setScaledHeight(`${scaler * canvasHeight}px`);
    setMapKey(Math.random());
    setScale(scaler);
  }, [width, canvasOptions, theme]);

  React.useEffect(() => {
    const updateAddress = async () => {
      const address = await getAddress(center);
      setTextOptions({
        ...textOptions,
        h1: address.city || "City",
        h2: address.country || "Country",
      });
    };
    !center &&
      (data.length > 0
        ? setCenter(data[0].points[0])
        : setCenter(defaultOptions.defaultCenter));
    if (textOptions.h1 === "unset" && center) {
      updateAddress();
    }
  }, [data, setCenter, center, setTextOptions, textOptions]);

  const generateCoords = (cent) => {
    const latitude =
      cent.lat > 0
        ? `${cent.lat.toFixed(6)}° N`
        : `${-1 * cent.lat.toFixed(6)}° S`;
    const longitude =
      cent.lng > 0
        ? `${cent.lng.toFixed(6)}° E`
        : `${-1 * cent.lng.toFixed(6)}° W`;

    return `${latitude} / ${longitude}`;
  };

  return (
    <div
      className="map-container"
      id="map-container"
      ref={parentEl}
      style={{
        width: "100%",
        height: `${scaledHeight}`,
      }}
    >
      <Map
        key={mapKey}
        style={styles}
        id="my-map"
        ref={mapRef}
        center={center}
        zoom={zoom}
        preferCanvas={true}
        animate={true}
        onzoomend={(e) => setZoom(e.target.getZoom())}
        ondragend={(e) => {
          const cent = e.target.getCenter();
          setCenter(cent);
          (textOptions.h3[2] === "." || textOptions.h3[1] === ".") && // Change coordinates only if user has not changed the text
            setTextOptions({
              ...textOptions,
              h3: generateCoords(cent),
            });
        }}
      >
        {theme[0] !== "Black" && theme[0] !== "White" && (
          <TileLayer detectRetina={true} url={theme[1]} />
        )}
        {data.length > 0 && (
          <Polyline {...lineOptions} positions={data.map((t) => t.points)} />
        )}
        {textOptions.h1 !== "unset" && (
          <LocationText
            textOptions={textOptions}
            scale={scale}
            type={canvasOptions.type}
          />
        )}
      </Map>
    </div>
  );
};
export default MapContainer;
