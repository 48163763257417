import React from "react";
import "./index.css";

const LocationText = ({ textOptions, scale, type }) => {
  const textStyles = {
    div: {
      color: `${textOptions.color}`,
      fontFamily: `${textOptions.font}`,
      bottom: `${
        1.1 / scale + (type === "Canvas" ? 2.5 * 59.05511811 : 0) //  150 dpi Margin for canvas
      }px`,
    },
    headLine: {
      letterSpacing: `${6 / scale}px`,
      marginBottom:
        textOptions.h2Visible || textOptions.h3Visible //Lift header up if only text visible
          ? `${-15 / scale}px`
          : "0px",
      fontSize: `${40 / scale}px`,
    },
    divider: {
      letterSpacing: `${10 / scale}px`,
      marginBottom: `${10 / scale}px`,
      fontSize: `${22 / scale}px`,
    },
    location: {
      letterSpacing: `${2 / scale}px`,
      fontSize: `${16 / scale}px`,
    },
  };
  return (
    <div id="location-text" className="title-div" style={textStyles.div}>
      {textOptions.h1Visible && (
        <div className="title-headline" style={textStyles.headLine}>
          <p>{textOptions.h1}</p>
        </div>
      )}
      <div className="title-description">
        {textOptions.h2Visible && (
          <div className="title-divider" style={textStyles.divider}>
            <p>{textOptions.h2}</p>
          </div>
        )}
        {textOptions.h3Visible && (
          <div className="title-coordinate">
            <p className="title-location" style={textStyles.location}>
              {textOptions.h3}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
export default LocationText;
