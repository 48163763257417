import dark from "./dark.jpeg";
import light from "./light.jpeg";
import colorful from "./colorful.jpeg";
import midnight from "./midnight.jpeg";

export const popularImages = {
  dark,
  light,
  colorful,
  midnight,
};
