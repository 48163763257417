import React from "react";
import {
  Accordion,
  Card,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import SelectableContext from "react-bootstrap/SelectableContext";
import { DateRange } from "react-date-range";
import {
  allOptions,
  popularOptions,
} from "../../utils/customizationOptions.js";
import TextOptionsForm from "./textOptions.js";
import ThemeOptionsForm from "./themeOptions.js";
import LineOptionsForm from "./lineOptions.js";
import { popularImages } from "../../assets/most_popular";
import "./index.css";

const Sidebar = ({
  canvasOptions,
  setCanvasOptions,
  lineOptions,
  setLineOptions,
  filter,
  setFilter,
  theme,
  setTheme,
  activityTypes,
  textOptions,
  setTextOptions,
  isLoading,
}) => {
  const createToggleButtonGroup = (
    type,
    optionGroup,
    optionName,
    options,
    setOption
  ) => {
    return (
      <ToggleButtonGroup
        type={type}
        name={optionGroup}
        style={{ display: "inline-block" }}
        defaultValue={optionGroup[optionName]}
        onChange={(val) =>
          setOption({ ...optionGroup, [`${optionName}`]: val })
        }
      >
        {options.map((option) => (
          <ToggleButton
            key={option}
            value={option}
            className="m-2 px-2 rounded-lg btn-info"
          >
            {option}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };
  return (
    <Accordion key={"sidebar"} id={`sidebar-selector}`}>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          Most popular settings
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={isLoading ? false : "0"}>
          <Card.Body>
            <ToggleButtonGroup
              type="radio"
              name="mostPopular"
              style={{ display: "inline-block" }}
              onChange={(val) => {
                setTextOptions({
                  ...textOptions,
                  font: val.text.font,
                  color: val.text.color,
                });
                setLineOptions(val.line);
                setTheme(val.theme);
              }}
            >
              {popularOptions.map((option) => (
                <ToggleButton
                  key={option.name}
                  value={option}
                  className="m-2 rounded-lg"
                  style={{
                    height: "60px",
                    width: "60px",
                    backgroundImage: `url(${popularImages[option.name]})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                  }}
                ></ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="1">
          Filter Activities
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={isLoading ? false : "1"}>
          <Card.Body>
            Select activity types:
            <br />
            {createToggleButtonGroup(
              "checkbox",
              filter,
              "activityTypes",
              activityTypes,
              setFilter
            )}
            <br />
            Select date range:
            <br />
            <DateRange
              ranges={[
                {
                  startDate: filter.minDate || new Date(2009, 1, 1),
                  endDate: filter.maxDate || new Date(),
                  key: "dateRange",
                },
              ]}
              onChange={(ranges) => {
                setFilter({
                  ...filter,
                  minDate: ranges.dateRange.startDate,
                  maxDate: ranges.dateRange.endDate,
                });
              }}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="2">
          Type
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={isLoading ? false : "2"}>
          <Card.Body>
            Select type:
            <br />
            {createToggleButtonGroup(
              "radio",
              canvasOptions,
              "type",
              allOptions.allCanvasOptions.type,
              setCanvasOptions
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="3">
          Size and Orientation
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={isLoading ? false : "3"}>
          <Card.Body>
            Select orientation:
            <br />
            {createToggleButtonGroup(
              "radio",
              canvasOptions,
              "orientation",
              allOptions.allCanvasOptions.orientation,
              setCanvasOptions
            )}
            <br />
            Select size:
            <br />
            {createToggleButtonGroup(
              "radio",
              canvasOptions,
              "size",
              allOptions.allCanvasOptions.size,
              setCanvasOptions
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="4">
          Text
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={isLoading ? false : "4"}>
          <SelectableContext.Provider value={false}>
            <Card.Body>
              <TextOptionsForm
                textOptions={textOptions}
                setTextOptions={setTextOptions}
              />
            </Card.Body>
          </SelectableContext.Provider>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="5">
          Line
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={isLoading ? false : "5"}>
          <Card.Body>
            <LineOptionsForm
              lineOptions={lineOptions}
              setLineOptions={setLineOptions}
            />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card style={{ overflow: "visible" }}>
        <Accordion.Toggle as={Card.Header} eventKey="6">
          Background
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={isLoading ? false : "6"}>
          <SelectableContext.Provider value={false}>
            <Card.Body>
              <ThemeOptionsForm theme={theme} setTheme={setTheme} />
            </Card.Body>
          </SelectableContext.Provider>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default Sidebar;
