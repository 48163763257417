import React from "react";
import logo from "../assets/Logo_headline.png";

export const loadingSpinner = (
  <div
    style={{
      height: "100vh",
      marginTop: "5vh",
      justifyContent: "center",
      display: "flex",
    }}
  >
    <style>
      {`
    @keyframes yAxis {
      50% {
        transform: rotateY(180deg);
      }

      100% {
        transform: rotateY(360deg);
      }
    }
    `}
    </style>
    <img
      src={logo}
      alt="loading spinner"
      style={{
        animation: "yAxis 3s ease-in-out infinite",
        width: "400px",
        height: "400px",
      }}
    />
  </div>
);
