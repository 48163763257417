import React from "react";
import { Form, Col, Button, Container } from "react-bootstrap";
import { productVariants } from "../../utils/customizationOptions.js";
import htmlToImage from "html-to-image";
import { sendEmail } from "../../utils/sendEmail.js";
import "./index.css";

const InfoForm = ({
  canvasOptions,
  imgEl,
  setMapImage,
  setLoading,
  setError,
}) => {
  const [isMailing, setMailing] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMailing(true);
    const formData = e.target.elements;
    const cartPermalink = `https://mapridesshop.com/cart/${
      productVariants[canvasOptions.type][canvasOptions.size]
    }:1?checkout[email]=${
      formData.formGridEmail.value
    }&checkout[shipping_address][city]=${
      formData.formGridCity.value
    }&checkout[shipping_address][zip]=${
      formData.formGridPostalcode.value
    }&checkout[shipping_address][address1]=${
      formData.formGridAddress1.value
    }&checkout[shipping_address][address2]=${
      formData.formGridAddress2.value
    }&checkout[shipping_address][first_name]=${
      formData.formGridFirstname.value
    }&checkout[shipping_address][last_name]=${
      formData.formGridLastname.value
    }&checkout[shipping_address][phone]=${
      formData.formGridPhone.value
    }&checkout[shipping_address][country]=${formData.formGridCountry.value}`;
    handleImage(1, formData, cartPermalink);
  };

  const handleImage = (quality, formData, cartPermalink) => {
    setMailing(true);
    htmlToImage.toJpeg(imgEl.current, { quality }).then(async (dataUrl) => {
      const message = await sendEmail(
        canvasOptions,
        dataUrl,
        formData,
        setMailing
      );
      if (message !== "OK") {
        if (quality < 0.7) {
          return setError("email_error");
        } else {
          return handleImage(quality - 0.05, formData, cartPermalink);
        }
      } else {
        window.location.replace(cartPermalink);
      }
    });
  };

  return (
    <Container
      className="info-form"
      onSubmit={!isMailing ? handleSubmit : null}
    >
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" required placeholder="Email address" />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" required placeholder="Phone" />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridFirstname">
            <Form.Label>First name</Form.Label>
            <Form.Control type="text" required placeholder="First name" />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridLastname">
            <Form.Label>Last name</Form.Label>
            <Form.Control type="text" required placeholder="Last name" />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formGridAddress1">
          <Form.Label>Address</Form.Label>
          <Form.Control placeholder="Address" required />
        </Form.Group>

        <Form.Group controlId="formGridAddress2">
          <Form.Label>Address 2</Form.Label>
          <Form.Control placeholder="Apartment, suite, etc. (optional)" />
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridCountry">
            <Form.Label>Country</Form.Label>
            <Form.Control placeholder="Country" required />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control placeholder="City" required />
          </Form.Group>
          <Form.Group as={Col} controlId="formGridPostalcode">
            <Form.Label>Zip</Form.Label>
            <Form.Control placeholder="Zip" required />
          </Form.Group>
        </Form.Row>
        <Button
          variant="primary"
          type="submit"
          className="m-3 cartButton"
          disabled={isMailing}
        >
          {isMailing ? (
            <div>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
              Processing
            </div>
          ) : (
            "Add To Cart"
          )}
        </Button>
        <Button
          onClick={(e) => {
            setMapImage(null);
            setLoading(false);
          }}
          variant="danger"
          className="m-3 cartButton"
        >
          Cancel
        </Button>
      </Form>
      {canvasOptions.type === "Canvas" && (
        <p className="font-weight-lighter">
          The line you see on the edges of the map is the edge of the canvas.
          The area in the margin will be seen after the edge of the canvas. The
          line will not be seen on the final product.
        </p>
      )}
    </Container>
  );
};
export default InfoForm;
