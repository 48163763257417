const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET || "";
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";

const fetchActivities = async (token) => {
  let activities = [];
  let allFetched = false;
  let pageNumber = 1;
  let perPage = 200;
  const requestBatchSize = 10;
  while (!allFetched) {
    const promises = [];
    for (let page of Array.from(
      new Array(requestBatchSize),
      (x, i) => i + pageNumber //eslint-disable-line
    )) {
      promises.push(fetchActivityPage(page, perPage, token));
    }
    const data = await Promise.all(promises);
    activities = activities.concat(data.flat());
    if (activities.length < (pageNumber + requestBatchSize - 1) * perPage) {
      allFetched = true;
    } else {
      pageNumber += requestBatchSize;
    }
  }
  return activities;
};

const fetchActivityPage = async (pageNumber, perPage, token) => {
  const response = await fetch(
    `https://www.strava.com/api/v3/athlete/activities?per_page=${perPage}&page=${pageNumber}&access_token=${token}`,
    {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response.json();
};

const exchangeOAuthCode = async (code) =>
  fetch(
    `https://www.strava.com/api/v3/oauth/token?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&code=${code}&grant_type_=authorization_code`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => {
    if (res.status !== 200) {
      // console.log(`Failed to exchange code, status: ${res.status}`); disable logging in production
      return null;
    }
    return res.json();
  });

const ServerMethods = {
  strava: {
    exchangeOAuthCode,
    fetchActivities,
  },
};

export default ServerMethods;
