import React from "react";
import "./index.css";
import LocationText from "../location-text";

const MapImage = ({ mapDataUrl, textOptions, canvasOptions, theme, imgEl }) => {
  const [width, setWidth] = React.useState(0);
  const [scaledHeight, setScaledHeight] = React.useState("100%");
  const [scale, setScale] = React.useState(1);
  const [styles, setStyles] = React.useState({});
  const [marginOffset, setMarginOffset] = React.useState("0px");

  const imgParentEl = React.useRef(null);
  React.useLayoutEffect(() => {
    imgParentEl.current && setWidth(imgParentEl.current.offsetWidth);
  }, []);

  React.useEffect(() => {
    const { orientation, size, type } = canvasOptions;
    const toPixel = (cm) => cm * 59.05511811; //150dpi
    const generateSizes = (sizeOption) => {
      if (sizeOption === "A4") return [toPixel(29.7), toPixel(21)];
      if (sizeOption === "A3") return [toPixel(42), toPixel(29.7)];
      return [toPixel(sizeOption.slice(0, 2)), toPixel(sizeOption.slice(5, 7))];
    };
    const sizes = generateSizes(size);
    if (type === "Canvas") {
      sizes[0] += toPixel(2.5);
      sizes[1] += toPixel(2.5);
    }
    const canvasWidth = orientation === "Portrait" ? sizes[1] : sizes[0];
    const canvasHeight = orientation === "Portrait" ? sizes[0] : sizes[1];
    const scaler = width / canvasWidth;
    setStyles({
      width: `${canvasWidth}px`,
      height: `${canvasHeight}px`,
      transform: `scale(${scaler})`,
      transformOrigin: "top left",
    });
    setScaledHeight(`${scaler * canvasHeight}px`);
    setScale(scaler);
    setMarginOffset(type === "Canvas" ? `${-(toPixel(2.5) + 1)}px` : null);
  }, [width, canvasOptions]);

  return (
    <div>
      <div
        className="imageContainer"
        ref={imgParentEl}
        style={{
          height: `${scaledHeight}`,
          backgroundColor: `${theme[1]}`,
        }}
      >
        <div
          id="imageNode"
          style={{
            backgroundImage: `url(${mapDataUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            outline: marginOffset ? "2px dashed white" : "none",
            outlineOffset: `${marginOffset}`,
            ...styles,
          }}
        >
          <LocationText
            textOptions={textOptions}
            scale={scale}
            type={canvasOptions.type}
          />
        </div>
      </div>
      <div
        style={{
          opacity: "0",
        }}
      >
        <div
          id="fullImage"
          ref={imgEl}
          style={{
            backgroundImage: `url(${mapDataUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            ...styles,
            transform: `scale(1)`,
            backgroundColor: `${theme[1]}`,
          }}
        >
          <LocationText
            textOptions={textOptions}
            scale={scale}
            type={canvasOptions.type}
          />
        </div>
      </div>
    </div>
  );
};

export default MapImage;
