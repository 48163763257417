import React from "react";
import "./index.css";
import { loadingSpinner } from "../../utils/loadingSpinner.js";
import {
  defaultOptions,
  allOptions,
} from "../../utils/customizationOptions.js";
import StravaError from "../strava-error";
import MapContainer from "./mapContainer.js";
import Sidebar from "../sidebar";
import InfoForm from "../infoForm";
import { getToken, getActivities } from "../../api";
import MapImage from "../map-image";
import { screenshot } from "../../utils/screenshot.js";
import { detect } from "detect-browser";

import { Col, Row, Container, Button } from "react-bootstrap";

const Map = (props) => {
  const [data, setData] = React.useState(undefined);
  const [error, setError] = React.useState(null);
  const [center, setCenter] = React.useState(null);
  const [zoom, setZoom] = React.useState(12.0);
  const [canvasOptions, setCanvasOptions] = React.useState(
    defaultOptions.defaultCanvasOption
  );
  const [textOptions, setTextOptions] = React.useState(
    defaultOptions.defaultTextOptions
  );
  const [lineOptions, setLineOptions] = React.useState(
    defaultOptions.defaultLineOptions
  );
  const [filter, setFilter] = React.useState(defaultOptions.defaultFilter);
  const [theme, setTheme] = React.useState(defaultOptions.defaultTheme);
  const [activityTypes, setActivityTypes] = React.useState(["Run"]);
  const [mapImage, setMapImage] = React.useState(undefined);
  const [isLoading, setLoading] = React.useState(false);

  const allActivityDataRef = React.useRef(null);
  const mapRef = React.useRef(null);
  const imgEl = React.useRef(null);

  const code = props.location.state.code;
  const scope = props.location.state.scope;
  const browser = detect();

  // Fetch users data from strava
  React.useEffect(() => {
    if (!code || scope !== "read,activity:read_all") {
      setData([]);
      !code ? setError("authorization") : setError("scope");
      return;
    }
    const fetchData = async () => {
      const token = await getToken(code);
      if (token) {
        const activities = await getActivities(token);
        if (!activities) {
          setData([]);
          setError("no_activities");
          return;
        }
        const activityTypes = [...new Set(activities.map((a) => a.type))];
        setActivityTypes(activityTypes);
        setData(activities);
        setFilter({
          ...filter,
          activityTypes: activityTypes,
        });
        allActivityDataRef.current = activities;
      } else {
        setData([]);
        setError("authorization");
      }
    };
    fetchData();
  }, [code, scope]); //eslint-disable-line react-hooks/exhaustive-deps

  // Apply filters to data
  React.useEffect(() => {
    let filteredData = allActivityDataRef.current;
    if (filter.minDate && filteredData)
      filteredData = filteredData.filter((a) => a.date >= filter.minDate);
    if (filter.maxDate && filteredData)
      filteredData = filteredData.filter((a) => a.date <= filter.maxDate);
    if (filteredData)
      filteredData = filteredData.filter((a) =>
        filter.activityTypes.includes(a.type)
      );
    if (allActivityDataRef.current) setData(filteredData);
  }, [filter, allActivityDataRef.current]); //eslint-disable-line react-hooks/exhaustive-deps

  const onButtonClick = async () => {
    setLoading(true);
    screenshot(mapRef.current.leafletElement, setMapImage);
  };
  return (
    <div>
      {error && <StravaError error={error} />}
      {data ? (
        !mapImage ? (
          <div>
            <Container className="fullContainer">
              <Row>
                <Col md={8} className="mapColContainer">
                  <MapContainer
                    canvasOptions={canvasOptions}
                    data={data}
                    lineOptions={lineOptions}
                    textOptions={textOptions}
                    setTextOptions={setTextOptions}
                    theme={theme}
                    mapRef={mapRef}
                    center={center}
                    setCenter={setCenter}
                    zoom={zoom}
                    setZoom={setZoom}
                  />
                </Col>
                <Col md={4}>
                  <h3>Customize your map</h3>
                  <Sidebar
                    canvasOptions={canvasOptions}
                    setCanvasOptions={setCanvasOptions}
                    lineOptions={lineOptions}
                    setLineOptions={setLineOptions}
                    filter={filter}
                    setFilter={setFilter}
                    theme={theme}
                    setTheme={setTheme}
                    activityTypes={activityTypes}
                    textOptions={textOptions}
                    setTextOptions={setTextOptions}
                    isLoading={isLoading}
                  />
                  <div className="justify-content-center my-2 py-2">
                    {canvasOptions.type === "Canvas" && (
                      <p className="font-weight-lighter">
                        Please note that canvas takes 2,5 cm margin for edges.
                        You can see the edge in the preview.
                      </p>
                    )}
                    <h3 className="py-2">
                      Price:{" "}
                      {
                        allOptions.allPrices[canvasOptions.type][
                          canvasOptions.size
                        ]
                      }
                      €
                    </h3>
                    <b className="font-weight-light">FREE WORLDWIDE DELIVERY</b>
                    <p className="font-weight-lighter">
                      All the products will be shipped from Finland
                    </p>
                    {browser.name === "safari" && (
                      <p className="text-danger">
                        For Safari users: you can use the tool to customize the
                        map but due to technical reasons, we advise you to use
                        some other browser to create the final product and to
                        make the order.
                      </p>
                    )}
                    <Button
                      onClick={
                        !isLoading && browser.name !== "safari"
                          ? onButtonClick
                          : null
                      }
                      variant="primary"
                      className="p-2"
                      disabled={isLoading || browser.name === "safari"}
                    >
                      {isLoading ? (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>{" "}
                          Loading preview
                        </div>
                      ) : (
                        "Preview your Map"
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          <Container className="previewContainer">
            <Row className="justify-content-center">
              <Col md={8}>
                <MapImage
                  mapDataUrl={mapImage}
                  canvasOptions={canvasOptions}
                  textOptions={textOptions}
                  theme={theme}
                  imgEl={imgEl}
                />
              </Col>
              <Col md={4}>
                <h3>Shipping Information</h3>
                <InfoForm
                  imgEl={imgEl}
                  setMapImage={setMapImage}
                  setLoading={setLoading}
                  canvasOptions={canvasOptions}
                  setError={setError}
                />
              </Col>
            </Row>
          </Container>
        )
      ) : (
        <div className="loading-screen">
          <h2>Loading data from Strava...</h2>
          {loadingSpinner}
        </div>
      )}
    </div>
  );
};

export default Map;
