import leafletImage from "leaflet-image";

export const screenshot = (map, setImage) => {
  leafletImage(map, (err, canvas) => {
    if (err) {
      return window.alert(err);
    }
    setImage(canvas.toDataURL({ pixelRatio: 10 }));
  });
};
