import React from "react";
import "./index.css";
import { Alert, Button } from "react-bootstrap";

const errors = {
  scope: {
    heading: "Wrong scope given",
    text:
      "This means your activities won't be visible in your map. Try logging in again with required scope or return to the shop.",
  },
  authorization: {
    heading: "Authorization failed",
    text: "Try logging in again or return to the shop.",
  },
  no_activities: {
    heading: "Error loading activities",
    text: "Try logging in again or return to the shop.",
  },
  email_error: {
    heading: "Something went wrong!",
    text: "Please try again or return to the shop.",
  },
};
const StravaError = ({ error }) => {
  const [show, setShow] = React.useState(true);
  const oauth_url = process.env.REACT_APP_OAUTH_URL || "";
  const shop_url = "https://mapridesshop.com/";
  return (
    show && (
      <Alert
        variant="danger"
        onClose={() => setShow(false)}
        dismissible
        className="justify-content-center m-3"
      >
        <Alert.Heading>{errors[error].heading}</Alert.Heading>
        <p>{errors[error].text}</p>
        {error !== "email_error" && (
          <Button
            href={oauth_url}
            variant="info"
            className="mx-2 errorButton"
          >
            Try Again
          </Button>
        )}
        <Button href={shop_url} variant="danger" className="mx-2 errorButton">
          Return to the shop
        </Button>
      </Alert>
    )
  );
};
export default StravaError;
