import React from "react";
import { DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import { allOptions } from "../../utils/customizationOptions.js";
import "./index.css";

const ThemeOptionsForm = ({ theme, setTheme }) => {
  return (
    <div>
      Select Background Theme:
      <br />
      <DropdownButton
        className="m-2"
        as={ButtonGroup}
        title={theme[0]}
        variant="info"
        onClick={(e) => e.stopPropagation()}
      >
        {allOptions.allThemeOptions.map((theme, index) => {
          return (
            <Dropdown.Item
              key={index}
              eventKey={index}
              onSelect={(key) => setTheme(theme)}
            >
              {theme[0]}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>{" "}
    </div>
  );
};
export default ThemeOptionsForm;
