import React from "react";
import "./App.css";
import Container from "react-bootstrap/Container";
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import Login from "./components/strava-auth";
import Map from "./components/map";
import LoginHandler from "./components/login-handler.js";

const App = () => {
  return (
    <Router>
      <Container fluid className="pt-2 App">
        <header className="App-header p-3">
          <h2> Create Your Maprides Map</h2>
        </header>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" component={LoginHandler} />
          <Route path="/map" component={Map} />
          <Redirect to="/" />
        </Switch>
      </Container>
    </Router>
  );
};

export default App;
