import React from "react";
import { Redirect } from "react-router-dom";

const LoginHandler = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");
  const scope = params.get("scope");
  return (
    <Redirect
      to={{
        pathname: "/map",
        state: { code, scope },
      }}
    />
  );
};

export default LoginHandler;
