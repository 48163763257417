import APIServer from "../server";
import polyline from "@mapbox/polyline";

export const getToken = async (code) => {
  try {
    const response = await APIServer.strava.exchangeOAuthCode(code);
    return response ? response.access_token : null;
  } catch (e) {
    return null;
  }
};

export const getActivities = async (token) => {
  try {
    const activitiesRaw = await APIServer.strava.fetchActivities(token);
    const activities = await activitiesRaw
      .filter((activity) => activity.map.summary_polyline)
      .map((a) => ({
        name: a.name || "Unknown",
        type: a.type || "Unknown",
        date: new Date(a.start_date_local),
        points: polyline.decode(a.map.summary_polyline),
      }))
      .sort((a, b) => b.date - a.date);
    return activities;
  } catch (e) {
    // console.log(e); disable logging in production
    return null;
  }
};
