import React from "react";
import { Form } from "react-bootstrap";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import "./index.css";

const LineOptionsForm = ({ lineOptions, setLineOptions }) => {
  return (
    <div>
      Select Color:
      <br />
      <div>
        <ColorPicker
          animation="slide-up"
          color={lineOptions.color}
          enableAlpha={false}
          placement="bottomRight"
          onChange={(colors) =>
            setLineOptions({
              ...lineOptions,
              color: colors.color,
            })
          }
          className="m-2"
        />
      </div>
      <Form>
        <Form.Group controlId="opacityRange">
          <Form.Label>Select Opacity: ({lineOptions.opacity})</Form.Label>
          <Form.Control
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={lineOptions.opacity}
            onInput={(e) =>
              setLineOptions({
                ...lineOptions,
                opacity: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form>
      <Form>
        <Form.Group controlId="weightRange">
          <Form.Label>Select Width: ({lineOptions.weight})</Form.Label>
          <Form.Control
            type="range"
            min="1"
            max="5"
            step="1"
            value={lineOptions.weight}
            onInput={(e) =>
              setLineOptions({
                ...lineOptions,
                weight: e.target.value,
              })
            }
          />
        </Form.Group>
      </Form>
    </div>
  );
};
export default LineOptionsForm;
