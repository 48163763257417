import React from "react";
import {
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import "rc-color-picker/assets/index.css";
import ColorPicker from "rc-color-picker";
import { allOptions } from "../../utils/customizationOptions.js";
import "./index.css";

const TextOptionsForm = ({ textOptions, setTextOptions }) => {
  return (
    <div>
      Select Color &amp; Font:
      <br />
      <div className="colorFontRow m-2">
        <ColorPicker
          animation="slide-up"
          color={textOptions.color}
          enableAlpha={false}
          placement="bottomRight"
          onChange={(colors) =>
            setTextOptions({
              ...textOptions,
              color: colors.color,
            })
          }
          className="m-2"
        />{" "}
        <DropdownButton
          className="m-2"
          as={ButtonGroup}
          title={textOptions.font}
          variant="info"
          onClick={(e) => e.stopPropagation()}
        >
          {allOptions.allFontOptions.map((font, index) => {
            return (
              <Dropdown.Item
                key={index}
                eventKey={index}
                onSelect={(key) =>
                  setTextOptions({
                    ...textOptions,
                    font: allOptions.allFontOptions[key],
                  })
                }
              >
                {font}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>{" "}
      </div>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Checkbox
            checked={textOptions.h1Visible}
            onChange={(e) =>
              setTextOptions({
                ...textOptions,
                h1Visible: !textOptions.h1Visible,
              })
            }
          />
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <InputGroup.Text>H1</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          disabled={!textOptions.h1Visible}
          placeholder="Header 1"
          value={textOptions.h1}
          onChange={(e) =>
            setTextOptions({
              ...textOptions,
              h1: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Checkbox
            checked={textOptions.h2Visible}
            onChange={(e) =>
              setTextOptions({
                ...textOptions,
                h2Visible: !textOptions.h2Visible,
              })
            }
          />
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <InputGroup.Text>H2</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          disabled={!textOptions.h2Visible}
          placeholder="Header 2"
          value={textOptions.h2}
          onChange={(e) =>
            setTextOptions({
              ...textOptions,
              h2: e.target.value,
            })
          }
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Checkbox
            checked={textOptions.h3Visible}
            onChange={(e) =>
              setTextOptions({
                ...textOptions,
                h3Visible: !textOptions.h3Visible,
              })
            }
          />
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <InputGroup.Text>H3</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          disabled={!textOptions.h3Visible}
          placeholder="Header 3"
          value={textOptions.h3}
          onChange={(e) =>
            setTextOptions({
              ...textOptions,
              h3: e.target.value,
            })
          }
        />
      </InputGroup>
    </div>
  );
};
export default TextOptionsForm;
