import React from "react";
import { Container, Button } from "react-bootstrap";
import stravaButton from "../../assets/strava_assets/btn_strava_connectwith_orange@2x.png";
import mapBackground from "../../assets/map_background.png";
import "./index.css";

const Login = () => {
  const oauth_url = process.env.REACT_APP_OAUTH_URL || "";
  return (
    <Container fluid>
      <div
        className="authBox"
        style={{
          backgroundImage: `url(${mapBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h2 className="authText">
          Authenticate with Strava to create your own Maprides print or canvas
        </h2>
        <div
          style={{
            height: "48px",
            width: "193px",
            margin: "2rem 0 0",
            backgroundImage: `url(${stravaButton})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Button
            href={oauth_url}
            style={{
              height: "100%",
              width: "100%",
              opacity: "0",
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default Login;
