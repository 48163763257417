export const sendEmail = async (
  canvasOptions,
  dataUrl,
  userData,
  setMailing
) => {
  const message = await window.Email.send({
    SecureToken: "80899f74-cb89-48a3-99b0-8f091d7035f9",
    To:
      canvasOptions.type === "Paper print"
      ? "prints@mapridesshop.com"
      : "canvas@mapridesshop.com",
    From: "erik@kasvua.io",
    Subject: `${canvasOptions.type} created for ${userData.formGridFirstname.value} ${userData.formGridLastname.value}`,
    Body: `Map information <BR />\
    Type: ${canvasOptions.type} <BR />\
    Size: ${canvasOptions.size} <BR />\
    Orientation: ${canvasOptions.orientation}<BR /><BR />\
    Shipping information <BR />\
    Name: ${userData.formGridFirstname.value} ${userData.formGridLastname.value} <BR />  \
    Email: ${userData.formGridEmail.value} <BR />\
    Phone: ${userData.formGridPhone.value} <BR />\
    Address 1: ${userData.formGridAddress1.value} <BR />\
    Address 2: ${userData.formGridAddress2.value} <BR />\
    Country: ${userData.formGridCountry.value} <BR />\
    City: ${userData.formGridCity.value} <BR />\
    Postal code: ${userData.formGridPostalcode.value} <BR />\
    `,
    Attachments: [
      {
        name: `maprides_${canvasOptions.orientation}_${canvasOptions.size}_${userData.formGridLastname.value}.jpeg`,
        data: dataUrl,
      },
    ],
  });
  setMailing(false);
  return message;
};
